import React, { useContext } from "react"
import { motion } from "framer-motion"
import Layout from "../components/Layout"
import Header from "../components/Header"
import { FancyLead, StyledArticle } from "../components/components.css"
import FancyTitle from '../components/FancyTitle'

import MenuButton from "../components/Menu/MenuButton"

import { MenuContext } from "../context/MenuContext"
import MainMenu from "../components/Menu/MainMenu"
import SecondaryMenu from "../components/Menu/SecondaryMenu"


function Index() {

  return (
    <Layout>
      <Header>
        <FancyTitle title="Hello, my name is Cameron." />
        <FancyLead>I'm a Full-Stack Web Developer based out of Hamilton, Ontario, Canada</FancyLead>
      </Header>
      <StyledArticle>
        <p className="content-lead">I specialize in building stunning user experiences for your online needs.</p>
      </StyledArticle>
    </Layout>
  )
}

export default Index
